<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      {{ $t("labels.physical_combo_program") }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="item.name"
            :label="$t('labels.program_name')"
            class="c-input-small"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" :key="`active_date_${item.id}`">
          <date-picker-model
            v-model="item.active_date"
            :label="$t('labels.date')"
            :min-date="minDate"
            :single-line="false"
          ></date-picker-model>
        </v-col> -->

        <v-col cols="12" :key="`start_time_${item.id}`">
          <date-time-picker
            v-model="item.start_time"
            :label="$t('labels.start_time')"
            :placeholder="$t('labels.start_time')"
            :single-line="false"
            :min-date="minDate"
          ></date-time-picker>
        </v-col>

        <v-col cols="12" :key="`end_time_${item.id}`">
          <date-time-picker
            v-model="item.end_time"
            :label="$t('labels.end_time')"
            :placeholder="$t('labels.end_time')"
            :single-line="false"
            :min-date="minDate"
          ></date-time-picker>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        v-if="!isDetailShow"
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment/moment";
import { httpClient } from "@/libs/http";

export default {
  name: "PhysicalComboProgramCreate",
  components: {},
  props: {
    updateItem: {
      type: Object,
      default: () => {},
    },
    isDetailShow: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    item: {
      name: null,
      // active_date: null,
      start_time: null,
      end_time: null,
      id: null,
    },
    isLoading: false,
  }),
  computed: {
    minDate() {
      return moment().add(1, "day").format("YYYY-MM-DD");
    },
    isDisabledBtn() {
      if (!this.item.name || !this.item.start_time || !this.item.end_time) {
        return true;
      }

      return false;
    },
    shopOptions() {
      if (!this.item.sources || this.item.sources.length == 0) {
        return [];
      }

      return [...this.shops]
        .filter((shop) => this.item.sources.includes(shop.id_e_marketplace))
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit("close");
    },

    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/physical-combo-program-save", this.item);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
